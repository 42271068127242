const types = {
  ETHEREUM_ACCOUNT_CHANGED: "ETHEREUM_ACCOUNT_CHANGED",
  ETHEREUM_ACCOUNT_FAILURE: "ETHEREUM_ACCOUNT_FAILURE",
  ETHEREUM_CHAIN_CHANGED: "ETHEREUM_CHAIN_CHANGED",
  ETHEREUM_INITIALIZE: "ETHEREUM_INITIALIZE",
  ETHEREUM_TRANSACTIONS_ADD: "ETHEREUM_TRANSACTIONS_ADD",
  ETHEREUM_TRANSACTIONS_UPDATE: "ETHEREUM_TRANSACTIONS_UPDATE",
};

export default types;
