import Web3 from "web3";

// export const provider = () => {
//   return new Web3.providers.HttpProvider(process.env.REACT_APP_RPC);
// };

// const web3 = new Web3(provider());

const web3 = new Web3(window.ethereum);

export async function checkConnection() {
  return (await web3.eth.getBlock("latest")).number;
}

export default web3;

//export const metamaskWeb3 = new Web3(window.ethereum);
